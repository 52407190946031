import {useCallback} from "react";

import {API, Auth} from 'aws-amplify'

import {DocWay, ISchedule} from "@/models";
import * as process from "process";
import {Status} from "@/models/ISchedule";

const api = new DocWay(
    process.env.REACT_APP_DOCWAY_ID,
    process.env.REACT_APP_DOCWAY_SECRET,
    process.env.REACT_APP_STAGE === 'true'
);

API.configure({
    endpoints: [
        {
            name: "APIGateway",
            endpoint: process.env.REACT_APP_AWS_API,
            custom_header: async () => ({
                'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
            })
        }
    ]
})

export default function useSchedule() {

    const listHourly = useCallback((date: Date): Promise<string[]> => {
        return api.listSchedule(date)
    }, []);

    const schedule = useCallback(async (date: Date) => {
        await API.post('APIGateway', '/schedule', {
            body: {
                date
            }
        })
    }, [])

    const listSchedule = useCallback(async (): Promise<ISchedule[]> => {
        const data: Array<any> = await API.get('APIGateway', '/schedule', {});
        return data.map(schedule => ({
            date: new Date(schedule.date['$date']),
            id: schedule.id,
            status: schedule.status,
            link: schedule.link
        } as ISchedule))
    }, [])

    const getSchedule = useCallback((id: number) => {
        return api.getSchedule(id)
    }, [])

    const cancelSchedule = useCallback(async (id: number) => {
        API.del('APIGateway', '/schedule', {
            body: {
                'id': id
            }
        })
    }, [])

    const lastScheduled = useCallback(async (): Promise<ISchedule | null> => {
        const resp = await listSchedule();
        const status = Status[1]
        const filtered = resp.filter(e => e.status === status);
        if (filtered.length === 0) {
            return null
        }
        return filtered[0];
    }, [listSchedule]);

    return {listHourly, schedule, listSchedule, getSchedule, cancelSchedule, lastScheduled}
}